<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonFavoritesPageInfo.label_page_title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col
          sm="12"
          xs="12"
          class="top-col mb-5">
          <top-nav-search
            class="top-nav-search"
            :showDescriptionText="showDescriptionText"
            :backLabel="gideonFavoritesPageInfo.label_back_description"
            :i18n="translations.components"
          >
          </top-nav-search>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-details mb-5" v-if="isToggled">
          <data-table
            v-if='translations.components'
            :title="this.gideonFavoritesPageInfo.name"
            :tooltip="tooltip"
            :fields="dataTableFields"
            :items="allFavorites"
            :toggle="showToggle"
            :noRecordsMessage="this.gideonFavoritesPageInfo.label_NoRecordsFound"
            :i18n="translations.components"
            @addFavorite="addFavoriteToCart"
            @deleteItem="deleteFavorite"
          ></data-table>
          <!-- / Data Tables -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import TopNavSearch from './TopNavSearch.vue'
import DataTable from '@/components/DataTableGideonStore.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-favorites',
  mixins: [translationMixin],
  data() {
    return {
      dataTableFields: [],
      isToggled: true,
      showDescriptionText: false,
      showToggle: true,
      storeLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Favorites'
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      tooltip: 'Testing',
      translations: {},
    }
  },
  components: {
    topNavSearch: TopNavSearch,
    dataTable: DataTable
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadStoreFavoritesPage: 'gideonStore/loadStoreFavoritesPage',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      updateStoreFavorites: 'gideonStore/updateStoreFavorites',
      updateStoreCart: 'gideonStore/updateStoreCart',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      this.storeNavPagePayload.lang = this.prefCulture
      this.storeLPPayload.lang = this.prefCulture
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-store', 'store-topnav-search'),
        await this.loadStoreFavoritesPage(this.storeLPPayload),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then(results => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)

        this.dataTableFields = [
          { key: 'vprd_product_name', label: this.gideonFavoritesPageInfo.label_product, sortable: true },
          { key: 'vprd_prd_code', label: this.gideonFavoritesPageInfo.label_item, sortable: true },
          {
            key: 'vprd_prd_display_price_cp',
            label: this.gideonFavoritesPageInfo.label_price,
            sortable: true,
            formatter: (value, key, item) => {
              let price = '$'+ Number(value).toFixed(2)
              return price
            },
          },
          { key: 'shopping_cart_type', label: this.gideonFavoritesPageInfo.label_type, sortable: true },
          {
            key: 'sfv_prd_key',
            label: 'Options',
            sortable: false,
            thStyle: {
              display: 'none',
            },
            tdStyle: {
              'text-align': 'center'
            }
          }
        ]
        this.setLoadingStatus(false)
      })
    },
    toggle() {
      this.isToggled = !this.isToggled
    },
    async addFavoriteToCart(data) {
      let cartPayload = {
        prd_key: data,
        quantity: 1
      }
      let response = false
      await Promise.all([
        (response = await this.updateStoreCart(cartPayload)),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingItem || 'There was an error adding the item to the cart.',
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      }
      this.$router.push({ name: 'store-cart' })
    },
    async deleteFavorite(data) {
      let favePayload = {
        prd_key: data.sfv_prd_key,
        isfavorite: 0
      }
      let response = false
      await Promise.all([
        (response = await this.updateStoreFavorites(favePayload)),
        await this.page_load()
      ])
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorDeletingFavorite || 'There was an error deleting the Favorite.',
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      } else {
        this.$swal({
          icon: 'success',
          text: this.translations.tcFavoriteDeleted || 'The Favorite was deleted.',
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      gideonFavoritesPageInfo: 'gideonStore/gideonFavoritesPageInfo',
      prefCulture: 'user/userPreferredCulture',
    }),
    allFavorites() {
      return [...this.gideonFavoritesPageInfo.Merchandise_Favorites, ...this.gideonFavoritesPageInfo.Scripture_Favorites];
    }
  },
  created() {
    this.page_load()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}
.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}
.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}
.switch-buttons {
  margin: 0 auto;
  text-align: center;
  .merchscriptBtn {
    background-color: #747474;
  }
  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}
.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  // padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;
  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    .category-ddl {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
      .btn-go {
        padding: 8px;
      }
    }
  }
  .tile-col-details {
    padding: 0;
  }
  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    .tile-col-card {
      // flex-grow: 2;
      // .icon {
      //   min-height: 75px;
      // }
    }
  }
}

.tiles {
  margin-bottom: 80px;
}

.arrow-down-icon{
    color:#000000;
    cursor: pointer;
}
.arrowup {
    cursor: pointer;
    img {
        transform: rotate(180deg);
    }
}
</style>
